import { gpt, bing, dalle, prodia, pixart } from '../gpti/main';

// 定义您的类型（根据实际情况调整）
type MessageType = {
    sender: string;
    text?: string;
    image?: string;
    time?: number;
};

type GptMessageType = {
    role: "user" | "assistant" | "system",
    content: string;
};

type conversation_style = "Balanced" | "Creative" | "Precise";

// handleAIMessage 函数
export const handleAIMessage = (
    selectedAI: string,
    selectedSubModel: string,
    userInput: string,
    messages: MessageType[],
    gptMessages: GptMessageType[],
    setMessages: React.Dispatch<React.SetStateAction<MessageType[]>>,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setGptMessages: React.Dispatch<React.SetStateAction<GptMessageType[]>>,
    userName: string,
    aiName: string,
    elapsedTimeRef: React.MutableRefObject<number>,
) => {

    if (selectedAI === 'gpt') {

        gpt({
            messages: gptMessages,
            // messages: [...gptMessages, { role: 'user', content: userInput }],
            // prompt: "你是一个写python代码的专家，接下来会帮助我解决各种fpython问题，或者使用python实现各种功能，项目等",
            prompt: userInput,
            model: selectedSubModel,
            markdown: false
        }, (err: any, data: any) => {
            setIsLoading(false); // 结束加载
            if (err != null) {
                console.log(err);
                // setMessages([...messages, { sender: userName, text: userInput }, { sender: 'Error', text: err.message }]);
            } else {
                // console.log(data);
                setMessages([...messages, { sender: userName, text: userInput }, { sender: aiName, text: data.gpt, time: elapsedTimeRef.current }]);
                // console.log(messages);
                setGptMessages([...gptMessages, { role: 'user', content: userInput }, { role: 'assistant', content: data.gpt }]);
                // console.log(gptMessages);
            }
        });

    } else if (selectedAI === 'bing') {

        bing({
            messages: [...gptMessages, { role: 'user', content: userInput }],
            // messages: [...gptMessages, { role: 'user', content: userInput }],
            // prompt: "你是一个写python代码的专家，接下来会帮助我解决各种fpython问题，或者使用python实现各种功能，项目等",
            conversation_style: selectedSubModel as conversation_style,
            markdown: false,
            stream: false
        }, (err: any, data: any) => {
            setIsLoading(false); // 结束加载
            if (err != null) {
                console.log(err);
            } else {
                // console.log(data);
                setMessages([...messages, { sender: userName, text: userInput }, { sender: aiName, text: data.message, time: elapsedTimeRef.current }]);
                // console.log(messages);
                setGptMessages([...gptMessages, { role: 'user', content: userInput }, { role: 'assistant', content: data.message }]);
                // console.log(gptMessages);
            }
        });

    } else if (selectedAI === 'dalle') {

        if (selectedSubModel === 'dalle.v1') {

            dalle.v1({
                prompt: userInput
            }, (err: any, data: any) => {
                setIsLoading(false); // 结束加载
                if (err != null) {
                    console.log(err);
                } else {
                    // console.log(data);
                    setMessages([...messages, { sender: userName, text: userInput }, { sender: aiName, image: data.images[0], time: elapsedTimeRef.current }]);
                    // console.log(messages);
                    setGptMessages([...gptMessages, { role: 'user', content: userInput }, { role: 'assistant', content: data.prompt }]);
                    // console.log(gptMessages);
                }
            });
        } else if (selectedSubModel === 'dalle.mini') {
            dalle.mini({
                prompt: userInput
            }, (err: any, data: any) => {
                setIsLoading(false); // 结束加载
                if (err != null) {
                    console.log(err);
                } else {
                    // console.log(data);
                    setMessages([...messages, { sender: userName, text: userInput }, { sender: aiName, image: data.images[0], time: elapsedTimeRef.current }]);
                    // console.log(messages);
                    setGptMessages([...gptMessages, { role: 'user', content: userInput }, { role: 'assistant', content: data.prompt }]);
                    // console.log(gptMessages);
                }
            });
        }
    } else if (selectedAI === 'prodia') {

        prodia.v1({
            prompt: userInput,
            data: {
                model: selectedSubModel,
                steps: 25,
                cfg_scale: 7,
                sampler: "DPM++ 2M Karras",
                negative_prompt: "bad anatomy,bad hands,text,error,missing fingers,extra digit,fewer digits,cropped,worst quality,jpeg artifacts,signature,watermark,username,blurry,bad feet"
            }

        }, (err: any, data: any) => {
            setIsLoading(false); // 结束加载
            if (err != null) {
                console.log(err);
            } else {
                // console.log(data);
                setMessages([...messages, { sender: userName, text: userInput }, { sender: aiName, image: data.images[0], time: elapsedTimeRef.current }]);
                // console.log(messages);
                setGptMessages([...gptMessages, { role: 'user', content: userInput }, { role: 'assistant', content: data.prompt }]);
                // console.log(gptMessages);
            }
        });
    } else if (selectedAI === 'prodia-Stable-Diffusion') {

        prodia.stablediffusion({
            prompt: userInput,
            data: {
                prompt_negative: "bad anatomy,bad hands,text,error,missing fingers,extra digit,fewer digits,cropped,worst quality,jpeg artifacts,signature,watermark,username,blurry,bad feet",
                model: selectedSubModel,
                sampling_method: "DPM++ 2M Karras",
                sampling_steps: 25,
                width: 512,
                height: 512,
                cfg_scale: 7
            }

        }, (err: any, data: any) => {
            setIsLoading(false); // 结束加载
            if (err != null) {
                console.log(err);
            } else {
                // console.log(data);
                setMessages([...messages, { sender: userName, text: userInput }, { sender: aiName, image: data.images[0], time: elapsedTimeRef.current }]);
                // console.log(messages);
                setGptMessages([...gptMessages, { role: 'user', content: userInput }, { role: 'assistant', content: data.prompt }]);
                // console.log(gptMessages);
            }
        });
    } else if (selectedAI === 'prodia-Stable-Diffusion-XL') {

        prodia.stablediffusion_xl({
            prompt: userInput,
            data: {
                prompt_negative: "bad anatomy,bad hands,text,error,missing fingers,extra digit,fewer digits,cropped,worst quality,jpeg artifacts,signature,watermark,username,blurry,bad feet",
                model: selectedSubModel,
                sampling_method: "DPM++ 2M Karras",
                sampling_steps: 25,
                width: 1024,
                height: 1024,
                cfg_scale: 7
            }

        }, (err: any, data: any) => {
            setIsLoading(false); // 结束加载
            if (err != null) {
                console.log(err);
            } else {
                // console.log(data);
                setMessages([...messages, { sender: userName, text: userInput }, { sender: aiName, image: data.images[0], time: elapsedTimeRef.current }]);
                // console.log(messages);
                setGptMessages([...gptMessages, { role: 'user', content: userInput }, { role: 'assistant', content: data.prompt }]);
                // console.log(gptMessages);
            }
        });
    } else if (selectedAI === 'pixart-a') {

        pixart.a({
            prompt: userInput,
            data: {
                prompt_negative: "bad anatomy,bad hands,text,error,missing fingers,extra digit,fewer digits,cropped,worst quality,jpeg artifacts,signature,watermark,username,blurry,bad feet",
                sampler: "DPM-Solver",
                image_style: "Anime",
                width: 1024,
                height: 1024,
                dpm_guidance_scale: 4.5,
                dpm_inference_steps: 14,
                sa_guidance_scale: 3,
                sa_inference_steps: 25
            }

        }, (err: any, data: any) => {
            setIsLoading(false); // 结束加载
            if (err != null) {
                console.log(err);
            } else {
                // console.log(data);
                setMessages([...messages, { sender: userName, text: userInput }, { sender: aiName, image: data.images[0], time: elapsedTimeRef.current }]);
                // console.log(messages);
                setGptMessages([...gptMessages, { role: 'user', content: userInput }, { role: 'assistant', content: data.prompt }]);
                // console.log(gptMessages);
            }
        });
    } else if (selectedAI === 'pixart-lcm') {

        pixart.lcm({
            prompt: userInput,
            data: {
                prompt_negative: "bad anatomy,bad hands,text,error,missing fingers,extra digit,fewer digits,cropped,worst quality,jpeg artifacts,signature,watermark,username,blurry,bad feet",
                image_style: "Fantasy art",
                width: 1024,
                height: 1024,
                lcm_inference_steps: 9
            }

        }, (err: any, data: any) => {
            setIsLoading(false); // 结束加载
            if (err != null) {
                console.log(err);
            } else {
                // console.log(data);
                setMessages([...messages, { sender: userName, text: userInput }, { sender: aiName, image: data.images[0], time: elapsedTimeRef.current }]);
                // console.log(messages);
                setGptMessages([...gptMessages, { role: 'user', content: userInput }, { role: 'assistant', content: data.prompt }]);
                // console.log(gptMessages);
            }
        });
    } else {
        setIsLoading(false); // 结束加载
        console.log('没有选择的模型');
    }

};
