import { createContext, useContext, useState } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userName, setUserName] = useState('星幻灵');
    const [aiName, setAiName] = useState('紫雪');

    const value = {
        userName,
        setUserName,
        aiName,
        setAiName
    };

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => {
    return useContext(UserContext);
};

