import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'; // 实心确认图标
import ChatContainer from './ChatContainer'; // Import the new Message component



type chatpagedata = {
    id: number;
    name: string;
}


export default function ChatComponent() {
    const [chatPages, setChatPages] = useState<chatpagedata[]>([{id: 0, name: "新对话"}]);
    const [selectedPageIndex, setSelectedPageIndex] = useState<number>(0);
    const [editIndex, setEditIndex] = useState(-1); // 用于标记正在编辑的项目的索引
    const [hidden, setHidden] = useState(false); // 用于标记正在编辑的项目的索引


    const createNewPage = () => {
        setChatPages([...chatPages, { id: chatPages[chatPages.length - 1].id + 1, name: '新对话' }]);
    };

    const handlePageClick = (index: number) => {
        setSelectedPageIndex(index);
    };

    const handleIconClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, pageToDelete: chatpagedata, index: number, mode: string) => {
        e.stopPropagation(); // 阻止事件冒泡
        if (mode === 'delete') {
            // 处理删除对话的点击事件
            if (chatPages.length === 1) {
                setChatPages([{ id: 0, name: '新对话' }]);
            } else {
                const newChatPages = chatPages.filter((page) => page !== pageToDelete);
                setChatPages(newChatPages);
            }

            if (selectedPageIndex === index) {
                setSelectedPageIndex(0);
            }
        } else if (mode === "edit") {
            // 处理编辑图标的点击事件
            setEditIndex(index); // 设置编辑状态
        }
    };

    // 处理输入框值的变化
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const updatedChatPages = [...chatPages];
        updatedChatPages[index].name = e.target.value;
        setChatPages(updatedChatPages);
    };

    // 保存编辑
    const handleSaveEdit = (index: number) => {
        setEditIndex(-1); // 取消编辑状态
    };

    // 处理输入框的键盘事件
    const handleInputKeyUp = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === 'Enter') {
            handleSaveEdit(index); // 按下回车键时保存编辑
        }
    };

    const handleHiddenClick = () => {
        setHidden(!hidden);
    };

    return (
        <div className="page-container">
            {hidden && (
            <div className="chat-sidebar">
                <button className="new-chat-btn" onClick={createNewPage}>+ 新对话</button>
                <ul className="chat-list">
                {chatPages.map((page, index) => (
                    <li 
                        key={index}
                        className={`chat-item ${index === selectedPageIndex ? 'selected' : ''}`}
                        onClick={() => handlePageClick(index)}
                    >
                        {editIndex === index ? ( // 根据编辑状态显示不同内容
                            <input
                                type="text"
                                value={page.name}
                                onChange={(e) => handleInputChange(e, index)}
                                onBlur={() => handleSaveEdit(index)}
                                onKeyUp={(e) => handleInputKeyUp(e, index)} // 检测回车键
                                autoFocus 
                            />
                        ) : (
                            <>
                                {page.name}
                                <span className="icon-config">
                                    <FontAwesomeIcon icon={faTrash} className="icon-fa delete" onClick={(e) => handleIconClick(e, page, index, 'delete')}/>
                                    <FontAwesomeIcon icon={faEdit} className="icon-fa edit" onClick={(e) => handleIconClick(e, page, index, 'edit')}/>
                                </span>
                            </>
                        )}
                    </li>
                ))}
                </ul>
            </div>
            )}
            {hidden && <FontAwesomeIcon className="hidden-chat-btn" icon={faChevronLeft}  onClick={handleHiddenClick}/>}
            {!hidden && <FontAwesomeIcon className="hidden-chat-btn" icon={faChevronRight}  onClick={handleHiddenClick}/>}

            {/* 循环渲染聊天页面 */}
            <div className='chat-container-all'>
                {chatPages.map((page, index) => (
                    chatPages[selectedPageIndex] === page && (
                    <ChatContainer />)
                ))}
            </div>

        </div>
        );
}
