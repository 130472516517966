// Message.tsx - A new component for individual messages
import React, { useState } from 'react';
import '../style/Message.css'; // Assuming we have a CSS file for styling
import { marked } from 'marked';    // 引入 marked 库
import RenderHTMLComponent from './renderHTML';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons'; // 导入线性（空心）图标
import { faCheck } from '@fortawesome/free-solid-svg-icons'; // 实心确认图标


type MessageProps = {
  sender: string;
  time?: number;
  text?: string;
  image?: string;
  align: 'left' | 'right';
  avatar: string; // URL to the sender's avatar image
};

const Message: React.FC<MessageProps> = ({ sender, time, text, image, align, avatar }) => {

  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    if (text !== undefined) {
      // 在这里安全地使用 text
      navigator.clipboard.writeText(text);
    } else if (image !== undefined) {
      // 在这里安全地使用 image
      navigator.clipboard.writeText(image);

    } else {
      // 如果 text 和 image 都没有值，不执行任何操作
      return;
    }

    setCopied(true);
    setTimeout(() => {
        setCopied(false);
    }, 1000); // 1秒后恢复
  };

  return (
    <div className={`message ${align}-align`}>
      <div className="message-info">
          <img src={avatar} alt={`${sender}'s avatar`} className="avatar" />
          
      </div>
      <div className="message-box">
        <div className="message-box-info">
          <div className="message-sender">{sender}</div>
          {align === 'left' && <p className='message-box-info-seconds'>{time?.toFixed(1) || 0}秒</p>}
          {/* <button onClick={copyToClipboard}>复制</button> */}
          {copied ? 
                <FontAwesomeIcon icon={faCheck} className="icon-copied" /> : 
                <FontAwesomeIcon icon={faCopy} className="copy-icon" onClick={copyToClipboard} />
            }
        </div>
        {text && <div className="message-text">
            <RenderHTMLComponent template={marked.parse(text)}></RenderHTMLComponent>
        </div>}
        {image && <img src={image} alt="AI Generated Content" className="message-image" />}
      </div>
    </div>
  );

};

export default Message;
